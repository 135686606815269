<template>
    <div id="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
					<div class="box">
						<h2>{{ $t('global.ajout_categorie') }}</h2>
						<form @submit.prevent="validForm">
							<!-- Nom -->
							<div class="form-group">
								<div class="row">
									<div class="col">
										<div class="input-group">
											<input class="form-control" type="text" :placeholder="$t('horse.categorie_nom')" v-model="categorienote_libelle" id="categorienote_libelle">
										</div>
									</div>
									<div class="col-auto">
										<button type="submit" v-on:click="checkForm" class="btn btn-primary">
										{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus-circle']"/>
									</button>
									</div>
								</div>
							</div>
						</form>
					</div>

					<div class="box">
						<LoadingSpinner v-show="screen_busy" class="col-12"/>

						<div v-if="!screen_busy">
							<h2>{{ $t('horse.categorie_existante') }}</h2>
							<b-form-checkbox v-if="hasDedicatedClientArea" class="mt-1 mb-1" v-model="order_categories" name="check-button" switch>
								{{ $t('horse.order_categories') }}
							</b-form-checkbox>

							<ul class="reset">
								<li class="d-flex align-items-center pt-2 pb-2">
									<span class="col-5">
										{{ $t('global.lang_fr') }}
									</span>
									<span class="col-5">
										{{ $t('global.lang_en') }}
									</span>
									<span class="col-1">
										{{ $t('global.public') }}
									</span>
								</li>

								<draggable 
									:list="note_categories" 
									v-bind="dragOptionsSelectedColumns"
									:move="triggMove"
									:disabled="!order_categories"
								>
									<li
										class="d-flex align-items-center pt-2 pb-2 border-top"
										v-for="cat in note_categories"
										:key="cat.categorienote_id"
									>
										<b-form-input
											class="mr-1 col-5"
											v-model="cat.categorienote_libelle"
											:placeholder="$t('horse.categorie_placeholder')"
											:readonly="!cat.isInEditMode"
										></b-form-input>

										<b-form-input
											class="mr-2 col-5"
											v-model="cat.categorienote_libelle_en"
											:placeholder="$t('horse.categorie_placeholder')"
											:readonly="!cat.isInEditMode"
										></b-form-input>

										<b-form-checkbox 
											class="mr-2"
											v-model="cat.categorienote_public"
											:unchecked-value=false
											:disabled="!cat.isInEditMode"
										></b-form-checkbox>


										<div v-if="order_categories" class="btn btn-secondary ml-auto order-md-last">
											<font-awesome-icon :icon="['fal', 'sort-alt']" />
										</div>
										<button v-if="!order_categories" @click="interactUpdateCat(cat)" class="btn btn-secondary ml-auto"><font-awesome-icon :icon="fontUpdateBtn(cat)" :spin="cat.isWorking" class="ml-1" /></button>
										<button v-if="!order_categories" @click="interactDeleteCat(cat)" class="btn btn-secondary ml-auto"><font-awesome-icon :icon="['fal', 'trash-alt']" class="ml-1" /></button>
									</li>
								</draggable>
						    </ul>
						</div>
					</div>
                </div>
            </div>
        </div>

        <ModalConfirm
            ref="modal_cat_delete"
            id="1"
            :icon="['fal', 'long-arrow-left']"
			:icon_accept="['fal', 'check']"
            :text_title="$t('modal.horse.suppression_categorie')"
            :text_question="$t('modal.horse.suppression_categorie_validation')"
            :text_message="modal_categorie_libelle"
            :text_button_accept="$t('global.supprimer')"
            :text_button_cancel="$t('global.annuler')"
            :callback_accept="applyDeleteCat" />
    </div>
</template>

<script type="text/javascript">
	import Notes from "@/mixins/Notes.js";
    import draggable from 'vuedraggable'
	import _orderBy from 'lodash/orderBy'

	export default {
		name: "CategoriesNote",
        mixins: [Notes],
        data () {
			return {
				modal_categorie_libelle: '',
				modal_categorie: '',
				categorienote_libelle: '',
				note_categories: [],
				screen_busy: true,
				order_categories: false,
				has_changed_order: false,

				dragOptionsSelectedColumns: {
                    animation: 200
                },
			}
		},
		computed: {
			hasDedicatedClientArea() {
				return this.$store.state.userAccess.hasDedicatedClientArea
			}
		},
		watch: {
			order_categories(val) {
				if(!val && this.has_changed_order) {
					this.saveCategoriesOrder(this.note_categories)
						.then(() => {
							this.has_changed_order = false
						})
				}
			}
		},
		mounted() {
            this.init_component() /* INTEGRER LES TOASTS */
		},
		beforeDestroy() {
			if(this.has_changed_order) {
				this.saveCategoriesOrder(this.note_categories)
			}
		},
		methods: {
            async init_component() {
				this.screen_busy = true

				this.getAllCategorieNote().then((res) => {
					res.forEach(item => {
						item.isInEditMode = false
						item.isWorking = false
					})

            		this.note_categories = _orderBy(res, 'categorienote_order')
					this.screen_busy = false
            	})
            },

            async validForm() {
            	if(this.categorienote_libelle === '')
            		return false
				
				try {
					await this.addCategorie(this.categorienote_libelle)

					this.successToast('toast.info_save_succes')
                    this.init_component()
                    this.categorienote_libelle = ''
				} catch {
					this.failureToast('toast.info_save_failed')
				}
            },

            /* Clic sur le bouton de suppression : Ouverture de la modale de confirmation */
            interactDeleteCat(cat) {
            	this.modal_categorie_libelle = cat.categorienote_libelle
            	this.modal_categorie = cat
            	this.$refs['modal_cat_delete'].openModal()
            },

			async interactUpdateCat(cat) {
				if(cat.isInEditMode) {
					cat.isWorking = true

					try {
						await this.updateCategorie(cat.categorienote_id, cat.categorienote_libelle, cat.categorienote_libelle_en, cat.categorienote_public)
					} catch {
						this.failureToast()
					}

					cat.isWorking = false
				}

				cat.isInEditMode = !cat.isInEditMode
			},

            /* Suppression effective de la catégorie */
			async applyDeleteCat() {
				try {
					await this.removeCategorie(this.modal_categorie.categorienote_id)
					this.init_component()

					this.$refs['modal_cat_delete'].closeModal()
					this.successToast()
				} catch {
					this.failureToast()
				}
			},

			triggMove() {
				this.has_changed_order = true
			},

			fontUpdateBtn(cat) {
				if(cat.isWorking) {
					return ['far', 'spinner']
				}
				return cat.isInEditMode ? ['far', 'save'] : ['fal', 'pencil-alt']
			}
		},
        components: {
			draggable,
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        }
	};
</script>
